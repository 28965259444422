<template>
    <div id="custom-google-button" @click="handleButtonClick()">
      <span class="icon"></span>
      <span class="button-text">Google</span>
    </div>
</template>

<script>

import { GOOGLE_CLIENT_ID } from "@/common/config";

export default {
  data() {
    return {
      //
    };
  },

  mounted() {

  },

  methods: {
    handleButtonClick() {
      const route = this.$router.resolve({
        name: 'google-redirect'
      });
      const redirectUrl = new URL(route.href, window.location.origin).href;

      const width = 500;
      const height = 600;
      const left = screen.width / 2 - width / 2;
      const top = screen.height / 2 - height / 2;

      const loginDialog = window.open(
        'https://accounts.google.com/o/oauth2/v2/auth?client_id=' + GOOGLE_CLIENT_ID + '&redirect_uri=' + redirectUrl + '&response_type=code&access_type=offline&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true',
        "GoogleLogin",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            width +
            ", height=" +
            height +
            ", top=" +
            top +
            ", left=" +
            left
      );

      const listener = (event) => {
        if (event.data.type === 'google-auth-code-fetched') {
          loginDialog.close();

          this.$emit('loginResponse', {
            code: event.data.code
          });

          removeEventListener('message', listener);
        }
      };

      window.addEventListener('message', listener);
    }
  }
};
</script>

<style scoped>
#custom-google-button {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  white-space: nowrap;
}
#custom-google-button:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url('/assets/icons/g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.button-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
</style>
